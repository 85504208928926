.asset-gallery {
  .filter-row {
    display: flex;

    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;

    .filter-input {
      padding-left: 5px;
      flex: 1;
      color: var(--col-darkest);
      background-color: var(--col-lightest);
    }

    .filter-icon {
      color: var(--col-dark);
      padding: 4px;
      height: 24px;
      width: 24px;
    }
  }

  .asset-grid {
    display: grid;
    padding: 8px;

    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-auto-rows: max-content;
    grid-gap: 8px;

    overflow-y: scroll;
    height: calc(
      100vh - 128px
    ); // Hacky: Hard code a fixed size offset so scroll area fits on screen

    box-sizing: border-box;
    padding-bottom: 100px;

    .asset-grid-item {
      border-color: var(--col-dark);
      border-width: 1px;
      max-width: 400px;
      max-height: 400px;

      .preview-image {
        height: 0;
        padding-top: 56.25%;
        position: relative;

        filter: brightness(90%);

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0px;
          left: 0px;
          bottom: 0px;
          right: 0px;

          width: 100%;
          height: 100%;
          object-fit: contain;
          background-color: var(--col-light);
        }
        p {
          width: 100%;
          position: absolute;
          bottom: 0px;
          text-decoration: none;
          opacity: 0;
          overflow-wrap: break-word;
        }
        &:hover {
          p {
            color: var(--col-light);
            text-shadow: 1px 1px 3px var(--col-darkest),
              -1px -1px 2px var(--col-darkest);
            opacity: 1;
          }
          box-shadow: 0 0 0 2px var(--col-primary);
          filter: brightness(100%);
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .side-dialog-container-collapsed {
    .filter-row {
      .filter-icon {
        padding: 2px;
        height: 16px;
        width: 16px;
      }
    }
  }
}
