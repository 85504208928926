.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  color: var(--col-darkest);
  z-index: 2000;

  background-color: rgba(0, 0, 0, 0.2);

  height: 100vh;
  width: 100vw;
}
