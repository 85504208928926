.asset-gallery {
  p {
    color: var(--col-darkest);
  }

  .search-row {
    display: flex;

    padding-left: 5px;
    padding-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    .search-input {
      color: var(--col-darkest);
      background-color: var(--col-lightest);
      padding-left: 5px;
      flex: 1;
    }
    .search-icon {
      color: var(--col-dark);
      padding: 4px;
      height: 24px;
      width: 24px;
    }
  }

  .pixabay-link {
    line-height: 20px;
    opacity: 0.7;
    font-size: 0.6rem;
    padding-left: 5px;
    color: var(--col-dark);
    a {
      text-decoration: underline;
    }
  }

  position: relative;
  .pixabay-progress-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    padding-top: 20vh;
    z-index: 2000;
    .pixabay-progress {
      width: 100px !important;
      height: 100px !important;
      color: var(--col-primary);
    }
  }
}

@media screen and (max-width: 1024px) {
  .side-dialog-container-collapsed {
    .search-row {
      .search-icon {
        padding: 2px;
        height: 16px;
        width: 16px;
      }
    }
  }
}
