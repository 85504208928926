.outliner {
  .text-property {
    * {
      color: var(--col-lightest);
    }
    padding-left: 5px;
    padding-right: 5px;
    font-weight: lighter;
    font-size: 1rem;

    .MuiFormControl-root {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    textarea,
    input {
      border-left: 2px solid var(--col-lightest);
      padding-left: 5px;

      &:focus {
        border-left-color: var(--col-primary);
        background-color: var(--col-primary-a2);
      }
    }
  }

  .slider-property {
    > div {
      display: flex;
    }

    * {
      color: var(--col-lightest);
    }

    // Override pointer events so that only the marker can be interacted with
    // This means the track/rail cannot be clicked to set a value
    .MuiSlider-root {
      pointer-events: none;
      .MuiSlider-thumb {
        pointer-events: all;
      }
    }

    padding-left: 5px;
    padding-right: 0px;
    margin-right: 0px;
    font-weight: lighter;
    font-size: 1rem;

    .MuiSlider-root {
      height: 2px;
      padding-top: 13px;
      padding-bottom: 14px;
      border-left: 2px solid var(--col-lightest);
    }

    .MuiSlider-markLabel {
      margin-left: 15px;
      font-size: 0.75rem;
    }
    .MuiSlider-mark {
      width: 10px;
      height: 10px;
      position: absolute;
      border-radius: 10px;
      top: calc(50% - 5.5px);
      margin-left: -5.5px;
      background-color: var(--col-light);
      opacity: 1;
    }
    .MuiSlider-thumb {
      color: var(--col-primary);
      width: 16px;
      height: 16px;
      margin-top: -7px;
      margin-left: -7px;
    }
    .reset-icon {
      width: 20px;
      height: 20px;
      padding-left: 10px;
      padding-right: 5px;
      padding-top: 4px;
      > * {
        color: var(--col-light);
        opacity: 0.8;
      }
      &:hover {
        > * {
          color: var(--col-primary);
          opacity: 1;
        }
      }
    }
  }

  .dropdown-property {
    * {
      color: var(--col-lightest);
    }
    padding-left: 5px;
    padding-right: 5px;

    .Mui-focused {
      background-color: var(--col-primary-a2);
    }
    .MuiInput-root {
      height: 29px;
      border-left: 2px solid var(--col-lightest);

      .MuiSelect-root {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  .asset-property {
    * {
      color: var(--col-lightest);
    }
    padding-left: 5px;
    padding-right: 5px;

    .asset-property-item:hover {
      background-color: var(--col-primary-a2);
      cursor: pointer;
    }

    .text {
      flex: 1;
      height: 29px;
    }
    .icon {
      height: 29px;
      width: 29px;
      * {
        color: var(--col-primary);
      }
    }
    // .Mui-focused {
    //   background-color: var(--col-primary-a2);
    // }
    // .MuiInput-root {
    //   height: 29px;
    //   border-left: 2px solid var(--col-lightest);

    //   .MuiSelect-root {
    //     padding-left: 5px;
    //     padding-right: 5px;
    //   }
    // }
  }
}
