.frame-editor-help-dialog {
  .frame-editor-help-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;

    padding: 10px;
  }
}
