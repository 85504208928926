.storyboard-preview-page {
  height: 100vh;
  background-color: var(--col-darkest);

  --frame-title-height: 30px;
  --frame-description-height: 45px;
  --share-height: 30px;

  // > * {
  //   > * {
  //     border: solid 1px white;
  //   }
  // }

  .storyboard-preview-page-container {
    height: var(--vh);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;

    .image-gallery {
      max-height: calv(
        var(--vh) - var(--frame-title-height) -var(--frame-description-height)
      );
    }

    .image-gallery-thumbnails-container {
      max-width: 90vw;
    }

    .image-gallery-image {
      @media only screen and (max-height: 500px) {
        width: 40%;
      }
      width: 100vh;
      max-width: 80vw;
    }
  }

  .frame-title {
    color: var(--col-lightest);
    height: var(--frame-title-height);
  }
  .frame-description {
    color: var(--col-lightest);
    height: var(--frame-description-height);
    min-height: var(--frame-description-height);
    max-height: var(--frame-description-height);

    max-width: 90vw;

    overflow: hidden;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .share-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: var(--share-height);
    * {
      font-size: 10px;
      font-style: italic;
    }
    p {
      color: var(--col-dark);
    }
    button {
      width: fit-content;
    }
  }
}
