.admin-projects-page {
  display: flex;
  justify-content: center;
  padding: 1vw;

  .table {
    display: grid;
    grid-template-columns:
      minmax(50px, 300px) minmax(0px, max-content) minmax(0px, max-content)
      minmax(0px, max-content) minmax(0px, max-content);
    grid-auto-rows: minmax(100px, auto);
  }

  .cell {
    padding: 2px;
    border: solid 1px var(--col-lightest);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
