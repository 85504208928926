.frame-card-a {
  text-decoration: none; /* no underline */
}

.frame-card {
  background-color: var(--col-light);

  border-color: var(--col-dark);
  border-width: 1px;
  border-style: solid;

  position: relative;

  .frame-title {
    position: absolute;
    bottom: 0px;
    right: 5px;
    text-decoration: none;
    color: var(--col-primary);
    text-shadow: 1px 1px 3px var(--col-darkest),
      -1px -1px 2px var(--col-darkest);
    font-weight: bold;
    z-index: 9;
  }

  .frame-updating-info {
    position: absolute;
    top: 30%;
    height: 40%;
    width: 100%;
    font-size: 0.8rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--col-light);
    color: var(--col-darkest);
    opacity: 0.9;

    > * {
      font-size: 0.8rem;
    }
  }

  .image-container {
    width: 100%;
    padding-top: 56.25%;
    position: relative;

    > img {
      position: absolute;
      top: 0px;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.new-frame-card {
  position: relative;
  color: var(--col-primary-dark);
  background-color: rgba(0, 0, 0, 0);
  border: 1px dashed var(--col-primary-dark);
  border-radius: 0px;
  box-shadow: 0 0;

  .title-container {
    position: absolute;
    top: 0px;

    // Center the text
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 100%;

    .title {
      font-size: 1.25rem;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: var(--col-primary-a2);
  }

  .new-frame-progress {
    position: absolute;
    color: var(--col-primary-dark);
  }
}
