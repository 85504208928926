.signin-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;

  .viso-logo {
    margin-top: 10%;
    margin-bottom: 20px;
    max-width: 300px;
  }
}
