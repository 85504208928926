.frame-card-and-actions {
  .actions {
    display: flex;
    flex-direction: row;
    justify-content: center;

    height: 44px;
    margin-top: 5px;
    margin-bottom: 5px;
    visibility: hidden;

    .action-icon {
      border-radius: 10px;
      padding: 10px;
      margin-left: 5%;
      margin-right: 5%;

      fill: var(--col-primary);
      &:hover {
        background-color: var(--col-primary-a2);
      }
    }
  }

  &:hover {
    .actions {
      visibility: visible;
    }
  }
}
