.project-grid {
  display: grid;

  grid-auto-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding: 20px;

  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  margin-left: 10%;
  margin-right: 10%;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    margin-left: 5%;
    margin-right: 5%;
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
    margin-left: 0;
    margin-right: 0;
  }
}
