.background-multi-gallery {
  .background-tabs {
    min-height: 100%;
    max-height: 100%;
    padding: 0px;

    .background-tab {
      padding: 0px;
      flex: 1;
      min-width: unset;
      max-width: unset;
      min-height: unset;
    }
    .MuiTabs-indicator {
      opacity: 0;
    }
    .Mui-selected {
      color: var(--col-darkest);
      background-color: var(--col-primary-a1);
    }
  }
}

@media screen and (max-width: 1024px) {
  .side-dialog-container-collapsed {
    .background-multi-gallery {
      .background-tabs {
        .MuiTabs-flexContainer {
          flex-direction: column;
        }
      }
      .asset-grid {
        height: calc(
          100vh - 200px
        ); // Hacky: Hard code a fixed size offset so scroll area fits on screen
      }
    }
  }
}
