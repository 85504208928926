.preview-image {
  height: 0;
  padding-top: 56.25%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;

    object-fit: contain;
    background-color: black;
  }
}

.no-url {
  img {
    background-color: var(--col-light);
  }
}
