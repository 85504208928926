.character-gallery {
  .asset-grid {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

    .asset-grid-item {
      .preview-image {
        padding-top: 177.77%;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .side-dialog-container-collapsed {
    .asset-grid {
      grid-template-columns: 1fr;
    }
  }
}
