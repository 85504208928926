.project-card-a {
  text-decoration: none; /* no underline */
}

.project-card {
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: var(--col-lightest);
  box-shadow: none;
  position: relative;
  border-style: solid;
  border-color: var(--col-lightest);
  border-width: 1px;

  .project-card-header {
    display: flex;
  }

  .body {
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }

  &:hover {
    box-shadow: 0 0 0 2px var(--col-primary-dark);
  }

  .title {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    color: var(--col-primary-dark);
    flex: 1;
    position: relative;

    &:hover {
      background-color: var(--col-primary-a2);
    }

    input {
      color: var(--col-primary-dark);
      font-size: 1.5rem;
      margin-right: 32px; // So we don't overlap text with the edit icon
      text-overflow: ellipsis;
    }
    .edit-icon {
      position: absolute;
      right: 0px;
      top: 0px;
      padding: 8px;
      height: 24px;
      width: 24px;
    }
  }

  .menu-button {
    color: var(--col-primary-dark);
    padding: 4px;
    height: 32px;
    width: 32px;
    &:hover {
      background-color: var(--col-primary-a2);
    }
  }

  .details {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 0.8rem;
    color: var(--col-dark);
  }

  .users {
    padding-left: 10px;
    padding-right: 10px;

    .user-badge {
      display: inline-block;
      padding: 4px;
      .MuiAvatar-root {
        width: 32px;
        height: 32px;
      }
    }
  }
}

.new-project-card {
  background-color: rgba(0, 0, 0, 0);
  border: 1px dashed var(--col-primary-dark);
  min-height: 300px;

  .title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0px;
    text-align: center;
    &:hover {
      background-color: unset;
    }
  }
  &:hover {
    cursor: pointer;
    background-color: var(--col-primary-a2);
    box-shadow: unset;
  }
}
