.user-manager {
  color: var(--col-darkest);
  padding: 32px;

  .title {
    min-height: 50px;
  }

  .user-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    color: var(--col-darkest);
    padding: 5px;
    min-height: 50px;
    position: relative;

    > :not(:first-child) {
      margin-left: 50px;
    }
    .user-badge {
      .MuiAvatar-root {
        width: 32px;
        height: 32px;
      }
    }

    .remove {
      position: absolute;
      right: 0px;
      width: 32px;
      height: 32px;
      color: var(--col-primary-dark);
      &:hover {
        background-color: var(--col-primary-a2);
      }
    }

    .name {
      flex: 1;
    }
    .email {
      flex: 1;
    }
  }

  .new-user-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 5px;
    min-height: 50px;
    position: relative;

    .new-user-input {
      flex: 1;
      max-width: 500px;
      input {
        color: var(--col-darkest);
        background-color: var(--col-lightest);
        padding: 0px;
        padding-left: 5px;
        height: 32px;

        &::placeholder {
          color: var(--col-darkest);
        }
      }
    }
    .add {
      margin-top: 8px;
      margin-bottom: 4px;
      width: 32px;
      height: 32px;
      color: var(--col-primary-dark);
      &:hover {
        background-color: var(--col-primary-a2);
      }
    }
  }
}
