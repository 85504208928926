.storyboard-wall-grid {
  display: grid;
  padding: 20px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: 1fr;
  grid-column-gap: 20px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    padding: 5px;
    grid-column-gap: 5px;
  }
}
