.frame-reel-modal {
  pointer-events: none;
}

.frame-reel-container {
  pointer-events: auto;
  outline: none;
  position: fixed;
  bottom: 20px;
  min-height: 126.5px;

  z-index: 1300; // modal (https://material-ui.com/customization/z-index/)

  width: 100%;
  overflow-x: auto;

  box-shadow: 0px 0px 10px var(--col-darkest);
  background-color: var(--col-lightest);

  .frames {
    display: inline-grid;
    grid-auto-flow: column;
    grid-gap: 5px;
    grid-template-columns: fit-content(0px);
    padding: 5px;

    margin-left: calc(50vw - 200px / 2);
    margin-right: calc(50vw - 200px / 2);

    .frame {
      position: relative;
      min-width: 200px;
      max-width: 200px;
      border: solid 2px var(--col-dark);
    }

    .frame:not(.current-frame):hover {
      border: solid 2px var(--col-primary-a1);
    }

    .current-frame {
      img {
        filter: saturate(0.2) blur(1px) brightness(0.5);
      }
    }

    .current-frame-actions {
      filter: none;
      position: absolute;
      top: 0px;
      height: 100%;
      min-width: 200px;
      max-width: 200px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      > svg {
        width: 32px;
        height: 32px;
        padding: 8px;
        color: var(--col-primary);
        border: solid 2px var(--col-primary);
        border-radius: 10px;
        &:hover {
          background-color: var(--col-primary-a1);
        }
      }
    }

    .new-frame {
      > * {
        align-self: center;
        height: 100%;
        margin: auto;
        padding-left: 5px;
        padding-right: 5px;
        width: 30px;

        color: var(--col-primary);
        background-color: var(--col-primary-a2);

        opacity: 0;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
