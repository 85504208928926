.background-manager {
  color: var(--col-darkest);
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;

  .title {
    min-height: 50px;
  }

  .main-actions-bar {
    display: flex;
    flex-direction: row;
    align-items: center;

    .main-action-button {
      color: var(--col-primary-dark);
      font-size: 1rem;
      min-width: 100px;

      &:hover {
        background-color: var(--col-primary-a2);
      }
    }
  }
  .asset-gallery {
    .asset-grid {
      height: calc(100vh - 250px);
    }
  }
}
