.dnd-container {
  position: relative;
}

.dnd-container.dnd-draggable {
  > * {
    box-shadow: 0px 5px 5px 0px var(--col-dark);
  }

  // Indicate card pick up for pointer hover events
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      transform: translate(5px, -5px) rotate(-1deg);
      -webkit-backface-visibility: hidden;
      box-shadow: 0px 5px 10px 2px var(--col-darkest);
    }
  }

  // Indicate card pick up for touch screen devices
  &:active,
  &.dnd-drag {
    &:not(.dnd-hover) {
      transform: translate(5px, -5px) rotate(-1deg);
      -webkit-backface-visibility: hidden;
      box-shadow: 0px 5px 10px 2px var(--col-darkest);
      opacity: 0.2;
    }
  }
}

.dnd-hover {
  &:not(.dnd-drag) {
    > * {
      transform: translate(20px, 0);
    }
    .dnd-hover-indicator {
      border-left: 4px solid var(--col-primary);
      position: absolute;
      top: 0px;
      left: -22px;
      height: 90%;
      top: 5%;
    }
  }
}
