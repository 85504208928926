:root {
  --col-darkest: #1c1c1c;
  --col-dark: #8b8b8b;

  --col-primary: #f8b133;
  --col-primary-a1: rgba(248, 177, 51, 0.5);
  --col-primary-a2: rgba(248, 177, 51, 0.2);
  --col-primary-dark: #be7c07;

  --col-light: #eeeeee;
  --col-lightest: #f5f5f5;

  --col-info: #2c2c2c;
  --col-success: #80b047;
  --col-warning: #fda00f;
  --col-danger: #f44336;

  --outliner-width: 240px;
  --appbar-height: 40px;
  --sidebar-dialog-width: 380px;
  --outliner-item-height: 40px;

  @media screen and (max-width: 1024px) {
    --outliner-width: 100px;
    --appbar-height: 32px;
    --sidebar-dialog-width: 100px;
    --outliner-item-height: 24px;
    font-size: 60%;
  }
}

body {
  background-color: var(--col-light);
  margin: 0px;
}

button:focus {
  outline: 0;
}

.MuiMenu-paper {
  background-color: var(--col-darkest);
  width: 200px;
  border-radius: 0px;
  box-shadow: 0px 0px 2px 0px var(--col-dark);
  z-index: 1350;

  * {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }

  li {
    min-height: unset;
    max-height: unset;
    padding-left: 10px;
    color: var(--col-light);
    font-weight: normal;
    font-size: 1.25rem;
  }

  .MuiDivider-root {
    background-color: var(--col-primary);
  }
}

.MuiDialog-root {
  * {
    color: var(--col-darkest);
  }

  .MuiDialog-paper {
    background-color: var(--col-lightest);
  }

  // span:hover {
  //   background-color: var(--col-primary-a2);
  // }

  // Style tab indicator
  .MuiTabs-scroller {
    > span {
      background-color: var(--col-primary);
    }
  }
}

// Utility classes

// Prevents text highlighting (can help on touch devices)
.unselectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
