.app-bar-profile-button {
  --padding: calc(var(--appbar-height) / 10);
  --inner-size: calc(var(--appbar-height) - 2 * var(--padding));

  padding: var(--padding);
  .MuiAvatar-root {
    height: calc(var(--inner-size));
    width: calc(var(--inner-size));
  }
}
