.outliner {
  .MuiDrawer-paper {
    top: var(--appbar-height);
    width: var(--outliner-width);
    background-color: var(--col-dark);
    border-right: 0px;
    height: calc(100% - var(--appbar-height));

    > :last-child {
      padding-bottom: 50px;
    }
  }

  .outliner-a {
    text-decoration: none; /* no underline */
  }
}

.outliner-partner-page {
  margin-left: var(--outliner-width);
}

.outliner-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  padding-left: calc(var(--outliner-width) / 100);
  color: var(--col-lightest);
  height: var(--outliner-item-height);
  min-height: var(--outliner-item-height);

  .MuiSvgIcon-root {
    color: var(--col-primary);
    padding: 2px;
  }

  .text-input {
    color: var(--col-lightest);
    font-weight: lighter;
    font-size: 1rem;
    height: 24px;
    padding: 0px;
    &.Mui-focused {
      color: var(--col-darkest);
    }
  }

  .outliner-header {
    color: var(--col-light);
    font-weight: bolder;
    .highlight {
      color: var(--col-lightest);
      font-weight: lighter;
    }
  }
  .outliner-text {
    font-weight: lighter;
  }
}
.outliner-item.selectable:hover {
  background-color: var(--col-primary-a2);
}

.outliner-item.selectable.selected {
  background-color: var(--col-primary-a1);
}

.outliner-header:not(.selectable) {
  color: var(--col-lightest);
}

.outliner-divider {
  background-color: var(--col-light);
  opacity: 0.8;
  &.major {
    height: 2px;
  }
}

.outliner-instance-text {
  input {
    pointer-events: none;
    color: var(--col-lightest);
    &:focus {
      color: var(--col-darkest);
      font-style: italic;
    }
  }
}
