.toolbox {
  // Fallback, when grid not supported
  display: flex;

  box-sizing: border-box;

  --button-size: 50px;
  @media screen and (max-width: 1024px) {
    --button-size: 40px;
  }

  > * {
    min-width: var(--button-size);
    max-width: var(--button-size);
  }

  display: grid;
  margin: 0px;
  margin-top: 4px;
  margin-bottom: 4px;

  grid-template-columns: repeat(auto-fill, minmax(var(--button-size), 1fr));
  grid-auto-rows: 1fr;
  grid-row-gap: 8px;
  justify-items: center;
}

.toolbox-item {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--button-size);
  height: var(--button-size);
  box-sizing: border-box;
  border: 1px solid var(--col-primary);
  border-radius: calc(var(--button-size) / 5);
  padding: 0px;

  color: var(--col-primary);
  background-color: var(--col-darkest);

  .MuiSvgIcon-root {
    opacity: 1;
    width: 66%;
    height: 66%;
    padding: 16%;
  }

  &:hover:not(.in-progress) {
    filter: brightness(100%);
    opacity: 0.8;
  }

  &.in-progress {
    .MuiSvgIcon-root {
      filter: brightness(50%);
    }
  }

  .toolbox-item-progress {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 66% !important;
    height: 66% !important;
    padding: 16%;
    color: var(--col-primary);
  }
}
