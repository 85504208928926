.storyboard-pdf {
  background-color: var(--col-lightest);
  .page {
    position: relative;
    padding: 30px;
    height: 793px; // A4 Landscape in pixels (rounded down)
    width: 1122px; // A4 Landscape in pixels (rounded down)
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-items: stretch;

    > * {
      width: 100%;
    }
    .header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-bottom: 10px;
      * {
        flex-grow: 1;
      }
    }

    .frames {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;

      .frame {
        .frame-title {
          height: 24px;
        }

        .description {
          // .description is for:
          // - text that wraps to three lines with ellipsis overflow
          // - underlines for the whole three lines with or without text
          position: relative;
          height: 100px;
          margin-top: 5px;

          .text {
            font-size: 0.8rem;
            margin: 0;
            text-align: justify;
            line-height: 30px;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3; /* number of lines to show */
          }

          .blank-lines {
            position: absolute;
            top: 0;
            width: 100%;
            height: 0px;

            * {
              margin-top: 29px;
            }
          }
        }
      }
    }

    .footer {
      position: absolute;
      bottom: 0px;
      left: 0px;
      padding: 30px;
      padding-top: 0px;
      color: var(--col-dark);
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 1122.52px; // A4 Landscape in pixels (rounded down)
      box-sizing: border-box;
    }
  }
}
