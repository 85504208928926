.app-bar-dummy {
  z-index: -1;
  opacity: 0;
  height: var(--appbar-height);
  position: sticky;
}
.app-bar {
  background-color: var(--col-darkest);
  height: var(--appbar-height);
  position: fixed;
  box-shadow: 0px 0px;
  border-bottom-color: var(--col-primary);
  border-bottom-style: solid;
  border-bottom-width: 1px;

  .tool-bar {
    display: flex;
    height: var(--appbar-height);
    min-height: unset;
  }

  // Helps maintain alignment of other elements
  .dummy-button-slot {
    height: 32px;
    width: 32px;
  }
}
