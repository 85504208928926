.side-dialog-modal {
  pointer-events: none;
  width: 100vw;
}

.side-dialog-container {
  width: var(--sidebar-dialog-width);
  pointer-events: auto;
  outline: none;
  position: fixed;
  right: 0px;
  // calc(
  //   100vw - var(--sidebar-dialog-width)
  // ); // Basically, right:0px - but that wasn't working correctly on chrome
  height: 100%;
  z-index: 1300; // modal (https://material-ui.com/customization/z-index/)
  background-color: var(--col-light);
  overflow: auto;
  box-shadow: 0px 0px 10px var(--col-darkest);
  overflow-y: hidden;

  .header-row {
    padding-left: 5px;

    display: grid;

    grid-template-columns: 1fr 1fr auto auto;
    grid-template-rows: 1fr;
    grid-template-areas: "title title";
    justify-items: start;
    align-items: center;

    .title {
      grid-area: title;
      color: var(--col-darkest);
      flex: 1;
    }

    .header-icon {
      color: var(--col-primary);

      height: 32px;
      width: 32px;

      &:hover {
        background-color: var(--col-primary-a2);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .side-dialog-container-collapsed {
    .header-row {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas: "title title";
    }
  }
}

.side-dialog-container-expanded {
  width: 100vw;
}
