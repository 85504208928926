.app-bar-help-button {
  --padding: calc(var(--appbar-height) / 10);
  --inner-size: calc(var(--appbar-height) - 2 * var(--padding));

  padding: var(--padding);

  color: var(--col-primary);

  img {
    height: calc(var(--inner-size));
    width: calc(var(--inner-size));
  }
  .MuiSvgIcon-root {
    height: calc(var(--inner-size));
    width: calc(var(--inner-size));
  }
  &:hover {
    background-color: var(--col-primary-a2);
  }
}
