.empty-project-layout {
  margin-top: 20px;
  margin-left: 50px;
  color: var(--col-darkest);
  width: 95%;
  @media screen and (max-width: 1024px) {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .title {
    margin-bottom: 20px;
  }

  .text {
    margin-bottom: 20px;
  }

  .new-storyboard-card {
    box-sizing: border-box;
    > div {
      display: flex;
      flex-direction: column;
    }

    color: var(--col-primary-dark);
    background-color: var(--col-primary-a2);
    border: 1px dashed var(--col-primary-dark);
    border-radius: 10px;
    padding: 20px;
    width: 90%;
    max-width: 500px;
    max-height: 500px;

    @media screen and (max-width: 1024px) {
      width: 100%;
      max-width: 350px;
    }

    input,
    textarea {
      padding-left: 2px;
      background-color: var(--col-lightest);
      margin-right: 10px;
    }
    .dropdown-property {
      margin-right: 10px;
      .MuiInput-input {
        padding-left: 2px;
        background-color: var(--col-lightest);
      }
    }
  }
}
